<template>
  <div class="app-units">
    <units-top-panel @create-unit="openModal" />
    <units-table />
    <units-modal :show.sync="modal.show" :title="modal.title" />
  </div>
</template>

<script>
import UnitsTable from './units-table/units-table.vue';
import UnitsModal from './units-modal/units-modal.vue';
import { FETCH_UNITS } from './shared/state/types/action_types';
import UnitsTopPanel from './units-top-panel/units-top-panel.vue';

export default {
  name: 'Meals',
  components: { UnitsTable, UnitsModal, UnitsTopPanel },
  data() {
    return {
      modal: {
        show: false,
        title: 'Добавить ед. измерения',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch(FETCH_UNITS, { page: 1 });
  },
  methods: {
    openModal() {
      this.modal.show = true;
    },
  },
};
</script>

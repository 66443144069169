<template>
  <div class="units-table mt-4">
    <app-table
      :loading="loading"
      :data="data ? data : []"
      :fields="fields"
      :rules="tableRules"
      @reload="reloadData"
      @edit="handleEdit"
      @delete="handleDelete"
    />
    <units-modal :show.sync="modal.show" :title="modal.title" action="edit" />
  </div>
</template>

<script>
import { AppTable } from '@/app/shared/components';
import { mapActions, mapGetters } from 'vuex';
import { LOADING, DATA, ERROR } from '../shared/state/types/getter_types';
import UnitsModal from '../units-modal/units-modal.vue';
import { SELECT_UNIT, DELETE_UNIT, FETCH_UNITS } from '../shared/state/types/action_types';
import { notifyMixin } from '../shared/mixins/notify-mixin';

export default {
  name: 'CategoryTable',
  components: { AppTable, UnitsModal },
  mixins: [notifyMixin],
  data() {
    return {
      fields: [
        { label: '#', value: 'id', width: 100 },
        { label: 'Название', value: 'title' },
      ],
      tableRules: [
        {
          value: 'edit',
          type: 'primary',
          icon: 'el-icon-edit',
        },
        {
          value: 'delete',
          type: 'danger',
          icon: 'el-icon-delete',
        },
      ],
      modal: {
        show: false,
        title: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      data: DATA,
      error: ERROR,
    }),
  },
  methods: {
    ...mapActions({ select: SELECT_UNIT, delete: DELETE_UNIT, fetch: FETCH_UNITS }),
    async reloadData(page) {
      await this.fetch({ page });
    },
    async handleEdit(unit) {
      this.select(unit);
      this.modal.show = true;
      this.modal.title = 'Редактирование ед. измерения';
    },
    handleDelete(unit) {
      this.$confirm('Вы точно хотите удалить?', 'Предупреждение', {
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Закрыть',
        type: 'warning',
      })
        .then(async () => {
          await this.delete(unit.id);
          if (this.error) {
            this.showErrorMessage('Ошибка удаления');
          }
          this.showSuccessMessage('Успешно удален');
        })
        .catch(() => {});
    },
  },
};
</script>
